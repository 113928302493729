#okta-sign-in.auth-container.main-container{
    margin-top: 4%;
}
.okta-sign-in-header{
    display: none;
}
.signInBackground{
    background: url('./images/img-home.jpg') center center/cover no-repeat;
    height:100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}