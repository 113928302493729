.MuiDataGrid-colCellTitleContainer{
    justify-content:center !important;
    overflow: unset !important;
    overflow-wrap: break-word;
    white-space: break-spaces !important;
    line-height: 1em;
    font-weight:bold !important;
    text-align:center;
    align-items: center;
    align-content:center;
    justify-content:center;
}
.MuiDataGrid-colCellTitle {
    overflow: unset !important;
    overflow-wrap: break-word;
    white-space: break-spaces !important;
    line-height: 1em;
    font-weight:bold !important;
    text-align:center;
    display:flex;
    justify-content:center;
}
.MuiDataGrid-cellLeft {
    text-align:center;
}
.MuiDataGrid-columnsContainer{
    flex-direction: unset !important;
}
