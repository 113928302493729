.report-style-class {
    height: 90vh;
    width:90vw;
    display:flex;
    justify-content:center;
    margin-top: 00px;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px
}
